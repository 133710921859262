import styled from 'styled-components';
import { Link } from 'gatsby';

export const Intro = styled.div`
  margin-top: 70px;

  h3 {
    font-family: Muli;
    font-size: 48px;
    line-height: 1;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 60px;
  }

  img {
    border-radius: 900px;
    border: 4px #ddd solid;
    padding: 5px;
    width: 350px;
  }
`;

export const Content = styled.div`
  text-align: left;
  line-height: 1.7;
  font-size: 22px;
  font-weight: 600;
  color: #52616a;
`;

export const SeeRecipes = styled(Link)`
  background: #faab06;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: -.5px;
  padding: 15px 30px;
  border-radius: 6px;
  border: 2px #fff solid;
  display: block;
  margin-bottom: 30px;
`;

export const SeeTestimonies = styled(SeeRecipes)`
  color: #faab06;
  background: #fff;
  border-color: #faab06;
`;
