import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Breaker from '../components/Breaker';
import SEO from '../components/Seo';
import { RECIPES_PATH, COACHING_PATH } from '../constants/Routes';

import Photo from '../images/Marta.jpg';
import { Intro, Content, SeeRecipes, SeeTestimonies } from '../styles/who';

const QuemSou = () => (
  <Layout>
    <SEO title="Quem sou" keywords={[`paleo`, `health`, `wellness`, `coach`, `fitness`, `coaching`, `chef`, `mindfulness`, `desporto`, `gym`, `ginasio`]} />
    <Header />

    <Grid>
      <Row>
        <Col md={12} style={{ textAlign: 'center' }}>
          <Intro>
            <h3>Olá, eu sou a Marta.</h3>
            <img src={Photo} alt="Marta Simōes" />
          </Intro>
        </Col>
      </Row>

      <Breaker />

      <Row center="md">
        <Col md={6}>
          <Content>
            Especialista em receitas Paleo, formadora, empresária e Health &amp;
            Wellness Coach, Instrutora de Nirvana Fitness e praticante assídua de mindfulness.

            <br /><br />

            Gestora de uma página com 30.000+ seguidores que corre Portugal de lés a lés todos os
            fins de semana. Vida social ativa, alegre... Feliz.

            <br /><br />

            Sai cedo e chega tarde, tem tempo de qualidade com as filhas, estuda muito...
            Procura aprender sempre mais e sonha... sonha muito.

            <br />

            Sonha com o restaurante, com a clinica... Sonha mudar o mundo e sabe que será capaz.

            <br /><br />

            Apaixonada pela família e pela vida! Inquieta... Sedenta de sabedoria e com um sentido humor
            fantástico.

            <br /><br />

            Esta sou eu... Marta das Cavernas, muito prazer.

            <br /><br />

            O que mudou? Tudo! Um renascimento daquilo que eu achava que eram as cinzas.
          </Content>
        </Col>
      </Row>

      <Breaker />

      <Row center="md">
        <Col md={8}>
          <Row center="xs">
            <Col md={6}>
              <SeeRecipes to={RECIPES_PATH}>
                Vê as minhas receitas
              </SeeRecipes>
            </Col>

            <Col md={6}>
              <SeeTestimonies to={COACHING_PATH}>
                Vê alguns testemunhos
              </SeeTestimonies>
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  </Layout>
);

export default QuemSou;
